import { Flex } from "@chakra-ui/react";
import HomebuyingStep from "../components/HomeBuyingSteps";

export default function Tasks() {

  const steps = [
    {
      title: 'Assess Your Finances',
      content: 'First-content',
    },
    {
      title: 'Conduct Market Research',
      content: 'Second-content',
    },
    {
      title: 'Find Properties',
      content: 'Last-content',
    },
    {
      title: 'Make an Offer',
      content: 'First-content',
    },
    {
      title: 'Negotiate Terms',
      content: 'Second-content',
    },
    {
      title: 'Schedule a Home Inspection',
      content: 'Last-content',
    },
    {
      title: 'Handle the Appraisal',
      content: 'First-content',
    },
    {
      title: 'Secure Your Mortgage',
      content: 'Second-content',
    },
    {
      title: 'Close the Sale',
      content: 'Last-content',
    },
    {
      title: 'Post-Closing Tasks',
      content: 'Last-content',
    },
  ];

  return (
    <Flex flex={1} gap={2}>
      <Flex direction="column" bg="white" width="fit-content" p={4} gap={6}>
        {steps.map(step => (
          <Flex align="center" gap={4}>
            <Flex height={35} width={35} borderRadius={35} borderColor="blue" borderWidth={1}></Flex>
            {step.title}
          </Flex>
        ))}
      </Flex>
      <Flex flex={1} height="100%" bg="white">

      </Flex>
    </Flex>
  )
}