import { type MenuProps } from 'antd';
import { AppstoreOutlined, MailOutlined, SettingOutlined } from '@ant-design/icons';
import { ReactNode, useState } from "react";
import { HomebuyingJourney } from "../components/HomebuyingJourney";
import HomebuyingStep from "../components/HomeBuyingSteps";
import { ChakraProvider, Flex, FormControl, FormErrorMessage, FormLabel, Heading, Image, Text, Button, Input, Card, CardBody, Icon } from '@chakra-ui/react'
import { Link, Outlet, useLocation } from "react-router-dom";
import Logo from '../FullLogo_Transparent_NoBuffer (1).png'
import { Field, FieldInputProps, FieldProps, Form, Formik, FormikProps } from 'formik';
import { FaSackDollar, FaFileLines } from "react-icons/fa6";
import { GiPathDistance } from "react-icons/gi";
import { ROUTES } from '..';
import firebase from '../firebase'
// Required for side-effects
import 'firebase/compat/firestore';
import { IconType } from 'react-icons';

const db = firebase.firestore();

async function addEmail(email: string) {
  try {
    await db.collection("waitlist").doc(email).set({
      createdAt: new Date()
    })
    console.log("Document successfully written!");
    return true
  } catch(error) {
      console.error("Error writing document: ", error);
      return false
  };
}

export default function Root() {
  const [current, setCurrent] = useState('mail');

  const onClick: MenuProps['onClick'] = (e) => {
    console.log('click ', e);
    setCurrent(e.key);
  };

  const location = useLocation()

  return (
    <ChakraProvider>
      <Flex flexDir="column" minHeight="100%" align="center" bg="#0C4773">
        <Flex flexDir="column" width="100%">
          <Flex align="center" justify="space-between" px={{base: 4, md: 20, lg: 40}} py={2} bg="white">
            <Flex gap={10} align="center">
              <Link to="/">
                <Image height={{base: 20, lg: 10}} src={Logo} />
              </Link>
              {/* <Link to="/">Home</Link> */}
              {/* <Link to="/">Pricing</Link>
              <Link to="/">FAQ</Link> */}
            </Flex>
            <Flex>
              <Link to="/login"><Text fontSize={{base: "32px", lg: "16px"}}>Login</Text></Link>
            </Flex>
          </Flex>
          <Flex flex={1} justify="center">
            {
              location.pathname.includes(ROUTES.LOGIN) ? <Outlet /> : <Landing />
            }
            
            
          </Flex>
        </Flex>
      </Flex>
    </ChakraProvider>
  )

}

const Landing = () => {

  const [emailSubmitted, setEmailSubmitted] = useState(false)

  function validateEmail(value: string) {
    let error
    if (!value || value.length === 0) {
      error = "Required"
    } else if (!value.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
      error = "Please enter a valid email"
    }

    return error
  }

  function isFieldInvalid(form: FormikProps<any>, field: FieldInputProps<any>) {
    const error = form.errors[field.name] as string
    const isTouched = form.touched[field.name]
    return isTouched && error && error.length > 0 ? true : false
  }

  async function submitEmail(email: string) {
    const isSuccess = await addEmail(email)
    if (isSuccess) {
      setEmailSubmitted(true)
    } else {
      alert('An error occurred. Please try again.')
    }
  }

  return (
    <Flex maxWidth="1000px" direction="column" align="center" justifyContent="center" flex={1} height="fit-content" py={{base: 10, lg: 40}} px={{base: 10, lg: 0}}>
      <Flex direction="column" align="center">
        <Heading mb={8} textAlign={{base: "center", lg: undefined}} size={{base: "4xl", lg: "xl"}} color="white">
          Guiding Your Path from Home Search to Settlement
        </Heading>
        <Text color="white" textAlign={{base: "center", lg: undefined}} fontSize={{base: "4xl", lg: "xl"}}>Take charge of your home-buying journey with confidence—<b style={{color: '#C25E14'}}>no agent required</b>. Our platform provides clear, step-by-step guidance from your initial search to closing the deal, giving you full control and transparency at every stage. Save time, reduce costs, and navigate the process with ease.</Text>
        </Flex>
      <Flex py={10} width="100%">
        {
          emailSubmitted ? (
              <Flex gap={4} p={4} borderRadius="md" bg="teal" direction="column" align="center" width="100%">
                <Text fontSize="lg" textAlign="center" color="#fff">Thank You for Joining Our Waitlist!</Text>
                <Text fontSize="md" textAlign="center" color="#fff">Stay tuned for updates—we’ll be in touch soon with exclusive early access and more details.</Text>
              </Flex>
          ) :
          (
            <Formik
              initialValues={{
                email: '',
              }}
              onSubmit={(values, actions) => {
                submitEmail(values.email)
              }}
            >
              {(props) => (
                <Form style={{width: "100%"}}>
                  <Flex direction={{base: "column", lg: "row"}} justifyContent="center" align={{base: "center", lg: "start"}} gap={{base: 6, lg: 2}}>
                    <Field name='email' validate={validateEmail}>
                      {({ field, form }: FieldProps) => (
                        <FormControl isInvalid={isFieldInvalid(form, field)} maxW={{base: "800px", lg: "600px"}}>
                          {/* <FormLabel>Email</FormLabel> */}
                          <Input 
                            {...field}
                            placeholder='email'
                            color="white" 
                            height={{base: "80px", lg: "40px"}}
                            fontSize={{base: "4xl", lg: "lg"}}
                          />
                          <FormErrorMessage fontSize={{base: "28px", lg: "16px"}}>{form.errors[field.name] as ReactNode}</FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Button
                      width={{base: "100%", lg: "fit-content"}}
                      maxW={{base: "800px", lg: "600px"}}
                      colorScheme='teal'
                      isLoading={props.isSubmitting}
                      type='submit'
                      height={{base: "80px", lg: "40px"}}
                      px={10}
                      fontSize={{base: "4xl", lg: "lg"}}
                    >
                      Get early access
                    </Button>
                  </Flex>
                </Form>
              )}
            </Formik>  
          )
        }
      
      </Flex>
      <Flex width="100%" align="center" gap={6} direction={{lg: "row", base: "column"}} mt={{base: 6, lg: 20}}>
        <InfoCard
          text="Avoid unnecessary fees and commissions"
          icon={FaSackDollar}
        />
        <InfoCard
          text="Access clear, easy-to-follow advice at every stage of the home-buying journey."
          icon={GiPathDistance}
        />
        <InfoCard
          text="Generate, review, and manage all essential documents securely in one place."
          icon={FaFileLines}
        />
      </Flex>
    </Flex>
  )
}

type InfoCardProps = {
  text: string
  icon: IconType
}

const InfoCard = ({ text, icon }: InfoCardProps) => {
  return (
    <Card flex={1} maxW={{base: "800px", lg: "600px"}} width="100%" height="175px">
      <CardBody>
        <Flex gap={4} direction="column" justifyContent="center" align="center">
          <Icon color="#404040" as={icon} boxSize={{base: 28, lg: 12}} />
          <Text fontSize={{base: "5xl", lg: 'lg'}} color="#404040" textAlign="center">{text}</Text>
        </Flex>
      </CardBody>
    </Card>    
  )
}