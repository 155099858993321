import { Button, ChakraProvider, Flex, Image } from "@chakra-ui/react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import Logo from '../FullLogo_Transparent_NoBuffer.png'
import { ROUTES } from "..";
import firebase from '../firebase'
import "firebase/compat/auth";
import { log } from "console";

export default function App() {
  const navigate = useNavigate()

  firebase.auth().onAuthStateChanged(function(user) {
    debugger
    if (!user) {
      navigate(ROUTES.LOGIN)
    }
  });

  const logout = () => {
    firebase.auth().signOut()
  }

  return (
    <ChakraProvider>
      <Flex flexDir="column" style={{minHeight: "100%"}} bg="#EEEEEE">
        <Flex bg="white" align="center" justify="space-between" px={14} py={2} mb={2}>
          <Flex gap={6} align="center">
            <Link to="/">
              <Image height={14} src={Logo} />
            </Link>
            <Link to="savedListings">Saved Listings</Link>
            <Link to={ROUTES.OFFER_ROOM}>Offer room</Link>
            <Link to="/login">Profile</Link>
          </Flex>
          <Flex gap={6}>
            
            <Button variant="link" onClick={logout}>Logout</Button>
          </Flex>
        </Flex>
        <Flex flex={1} justify="center">
          <Outlet />
        </Flex>
      </Flex>
    </ChakraProvider>
  )
}