import { Button, Flex, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from "@chakra-ui/react";
import HomeListItem from "../components/HomeListItem";

export default function SavedListings() {

  return (
    <Flex flex={1} maxW={1400} justify="center" bg="white" p={4}>
      <Tabs width="100%">
        <TabList>
          <Tab>Active</Tab>
          <Tab>Archived</Tab>
          <Button pos="absolute" right={0} top={-2}>Add listing</Button>
        </TabList>

        <TabPanels>
          <TabPanel>
            <Flex flexWrap="wrap" flex={1} height="fit-content" direction="row" width="100%" mb={10} gap={6} justify="center">
              <HomeListItem />
              <HomeListItem />
              <HomeListItem />
              <HomeListItem />
              <HomeListItem />
              <HomeListItem />
              <HomeListItem />
              <HomeListItem />
              <HomeListItem />
              <HomeListItem />
              <HomeListItem />
              <HomeListItem />
            </Flex>
          </TabPanel>
          <TabPanel>
            <Text>No homes have been archived at this time.</Text>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  )
}