import firebase from "firebase/compat/app";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAfCs8wP8jHRuc_QASJ9WKrojgv635NrME",
  authDomain: "maze-99977.firebaseapp.com",
  projectId: "maze-99977",
  storageBucket: "maze-99977.appspot.com",
  messagingSenderId: "97258804875",
  appId: "1:97258804875:web:44f9e3b421ff81cbdce68e"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default firebase