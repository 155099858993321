import { Flex, Text } from "@chakra-ui/react";

export default function OfferRoom() {

  return (
    <Flex flex={1} maxW={1400} justify="center" bg="white" p={4}>
      <Text>Offers you've started will apear here
      </Text>
    </Flex>
  )
}