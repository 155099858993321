import { Card, CardBody, Image, Text, Flex, Link as ChakraLink, Button, CardFooter, CardHeader, Badge, IconButton } from '@chakra-ui/react'
import { AiOutlineMore } from "react-icons/ai";
import { Link } from 'react-router-dom'
import { ROUTES } from '..';


enum STATUS {
  OFFER_STARTED,
  OFFER_COMPLETE,
  OFFER_SUBMITTED
}

export default function HomeListItem() {
  const statusNumber = getRandomInt(5)
  const status = statusNumber < 4 ? statusNumber : undefined

  return (
    <Flex flexBasis={["95%", "95%", "48%", "32%"]} height="fit-content">
        <Card> 
          <CardHeader p={0} px={4} pt={4} flex="row" alignItems="center">
            <Flex justify="space-between">
              <Badge py={2} px={4}  borderRadius={20} height="fit-content">status</Badge>
              <IconButton
                aria-label='menu'
                fontSize={30}
                bg="transparent"
                icon={<AiOutlineMore />} 
              />
            </Flex>
          </CardHeader>
          {/* <ChakraLink as={Link} to="/homeDetails" _hover={{opacity: .9, backgroundColor: "#eeeeee"}}> */}
            <CardBody>
              <Image src='https://plus.unsplash.com/premium_photo-1661962841993-99a07c27c9f4?q=80&w=3731&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D' />
              <Text my={4} fontSize={20} fontWeight={600}>$500,000</Text>
              <Flex my={4} direction="row">
                <Flex paddingRight={2} borderRightWidth={1} borderRightColor="gray">
                  <Text>3 bd</Text>
                </Flex>
                <Flex px={2} borderRightWidth={1} borderRightColor="gray">
                  <Text>2.5 ba</Text>
                </Flex>
                <Flex px={2}>
                  <Text>2,000 sqft</Text>
                </Flex>
              </Flex>
              <Text>2815 8th Avenue W Seattle, WA 98119</Text>
            </CardBody>
          {/* </ChakraLink> */}
          <Footer status={status} />
        </Card>
    </Flex>
  )
}

type FooterProps = {
  status: STATUS | undefined
}

const Footer = ({status}: FooterProps) => {
  if (status === undefined) { 
    return (
      <CardFooter borderTopWidth={1} borderTopColor="#eeeeee">
        <ChakraLink flex={1} as={Link} to={ROUTES.WRITE_OFFER}>
          <Button colorScheme="blue" width="100%" >Make an offer</Button>  
        </ChakraLink>
      </CardFooter> 
    )

  } else if (status === STATUS.OFFER_STARTED) {
    return (
      <CardFooter borderTopWidth={1} borderTopColor="#eeeeee">
        <Button colorScheme="green" flex={1}>Continue offer</Button>  
      </CardFooter> 
    )
  } else if (status === STATUS.OFFER_COMPLETE) {
    return (
      <CardFooter borderTopWidth={1} borderTopColor="#eeeeee">
        <Button colorScheme="red" flex={1}>Submit offer</Button>  
      </CardFooter> 
    )
  }

  return null

}

function getRandomInt(max: number) {
  return Math.floor(Math.random() * max);
}