import { Flex } from "@chakra-ui/react";
import OfferSteps from "./offerSteps";
import { Outlet } from "react-router-dom";
import { ROUTES } from "../..";

export default function WriteOffer() {

  const steps = [
    {
      label: 'Parties and property',
      link: ROUTES.PARTIES_AND_PROPERTY,
      children: [
        {
          label: 'Buyer info',
          link: ROUTES.BUYER_INFO
        },
        {
          label: 'Seller info',
          link: ROUTES.SELLER_INFO
        },
        {
          label: 'Inclusions',
          link: ROUTES.BUYER_INFO
        },
        {
          label: 'Parking and storage',
          link: ROUTES.BUYER_INFO
        }
      ]
    },
    {
      label: 'Dates and deadlines',
      children: [
        {
          label: 'Title'
        },
        {
          label: 'Owner\'s association'
        },
        {
          label: 'Seller\s Disclosures'
        },
        {
          label: 'Loan and credit'
        },
        {
          label: 'Appraisal'
        },
        {
          label: 'Survey'
        },
        {
          label: 'Inspection and due diligence'
        },
        {
          label: 'Closing and possession'
        },
        {
          label: 'Other'
        }
      ]
    },
    {
      label: 'Purchase price and terms',
    },
    {
      label: 'Provisions',
      children: [
        {
          label: 'Financing'
        },
        {
          label: 'Appraisal'
        },
        {
          label: 'Owners\s associations'
        }
      ]
    },
    {
      label: 'Title'
    },
    {
      label: 'Closing'
    },
    {
      label: 'General provisions'
    }
  ];

  return (
    <Flex flex={1} gap={2}>
      <OfferSteps steps={steps} />
      <Flex flex={1} height="100%" bg="white">
        <Outlet />
      </Flex>
    </Flex>
  )
}