import { Flex, Link as ChakraLink } from "@chakra-ui/react";
import { Link, useLocation } from "react-router-dom"
import { ROUTES } from "../..";

type OfferStep = {
  label: string
  link?: string
  children?: OfferStep[]
}

type OfferStepsProps = {
  steps: OfferStep[]
}

export default function OfferSteps({ steps }: OfferStepsProps) {

  const location = useLocation()
  const path = location.pathname

  const isCurrentStep = (step: OfferStep) => {
    debugger
    return step.link && path.includes(step.link)
  }

  return (
    <Flex direction="column" bg="white" width="fit-content" p={4} gap={6}>
      {steps.map(step => (
        <Flex justify="center" flexDir="column" gap={4}>
          <ChakraLink as={Link} to={step.link || ''} flexDirection="row" display="flex" alignItems="center" gap={4} _hover={{textDecoration: undefined, opacity: .5}}>
            <Flex  height={10} width={10} borderRadius={35} borderColor="blue" borderWidth={1}></Flex>
            {step.label}
          </ChakraLink>
          {
             isCurrentStep(step) && (
              <Flex ml={14} direction="column" gap={6}>
                {step.children?.map(child => (
                  <ChakraLink as={Link} to={child.link || ''} flexDirection="row" display="flex" alignItems="center" gap={4} _hover={{textDecoration: undefined, opacity: .5}}>
                    <Flex height={6} width={6} borderRadius={20} borderColor="blue" borderWidth={1}></Flex>
                    {child.label}
                  </ChakraLink>
                ))}
              </Flex>
            )
          }
        </Flex>
      ))}
    </Flex>
  )
}