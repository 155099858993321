import { Button, Card, CardBody, CardHeader, Flex, FormControl, FormErrorMessage, FormLabel, Heading, Input } from "@chakra-ui/react";
import { Field, Form, Formik, FieldProps, FormikProps, FieldInputProps } from 'formik';
import { ReactNode, useState } from "react";
import { AiOutlinePlus, AiOutlineClose } from "react-icons/ai";

export default function SellerInfo() {

  const [secondSellerAdded, setSecondSellerAdded] = useState(false)


  function validateSeller1Field(value: string) {
    let error
    if (!value || value.length === 0) {
      error = "Required"
    }

    return error
  }

  function validateSeller2Field(value: string) {
    let error
    if (secondSellerAdded && !value || value.length === 0) {
      error = "Required"
    }

    return error
  }

  function isFieldInvalid(form: FormikProps<any>, field: FieldInputProps<any>) {
    const error = form.errors[field.name] as string
    const isTouched = form.touched[field.name]
    return isTouched && error && error.length > 0 ? true : false
  }

  return (
    <Flex width="100%" direction="row" justify="center" m={10}>
      <Formik
        initialValues={{
          firstName1: '',
          lastName1: '',
          firstName2: '',
          lastName2: ''
        }}
        onSubmit={(values, actions) => {
          setTimeout(() => {
            alert(JSON.stringify(values, null, 2))
            actions.setSubmitting(false)
          }, 1000)
        }}
      >
        {(props) => (
          <Form style={{display: 'flex', flex: 1, justifyContent: 'center'}}>
            <Flex maxW="500px" width="60%" direction="column" gap={6}>
              <Heading size="md">Seller 1</Heading>
              <Field name='firstName1' validate={validateSeller1Field}>
                {({ field, form }: FieldProps) => (
                  <FormControl isInvalid={isFieldInvalid(form, field)}>
                    <FormLabel>First name</FormLabel>
                    <Input {...field} placeholder='first name' />
                    <FormErrorMessage>{form.errors[field.name] as ReactNode}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name='lastName1' validate={validateSeller1Field}>
                {({ field, form }: FieldProps) => (
                  <FormControl isInvalid={isFieldInvalid(form, field)}>
                    <FormLabel>Last name</FormLabel>
                    <Input {...field} placeholder='last name' />
                    <FormErrorMessage>{form.errors[field.name] as ReactNode}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              {!secondSellerAdded && <Button leftIcon={<AiOutlinePlus />} onClick={() => setSecondSellerAdded(true)} variant="link">Add additional seller</Button>}
              {
                secondSellerAdded && (
                  <>
                    <Flex direction="row" justifyContent="space-between">
                      <Heading size="md">Seller 2</Heading>
                      <Button leftIcon={<AiOutlineClose />} onClick={() => setSecondSellerAdded(false)} variant="link">Remove seller</Button>
                    </Flex>
                    <Field name='firstName2' validate={validateSeller2Field}>
                      {({ field, form }: FieldProps) => (
                        <FormControl isInvalid={isFieldInvalid(form, field)}>
                          <FormLabel>First name</FormLabel>
                          <Input {...field} placeholder='first name' />
                          <FormErrorMessage>{form.errors[field.name] as ReactNode}</FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field name='lastName2' validate={validateSeller2Field}>
                      {({ field, form }: FieldProps) => (
                        <FormControl isInvalid={isFieldInvalid(form, field)}>
                          <FormLabel>Last name</FormLabel>
                          <Input {...field} placeholder='last name' />
                          <FormErrorMessage>{form.errors[field.name] as ReactNode}</FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </>
                )
              }

              <Button
                mt={2}
                width="100%"
                colorScheme='teal'
                isLoading={props.isSubmitting}
                type='submit'
              >
                Continue
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    </Flex>
  )
}